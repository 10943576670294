import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import { pathToPrivacyPolicy } from '../../../utils/paths';
import {
  COOKIE_ACCEPT,
  COOKIE_DECLINE,
  COOKIE_EXPIRATION_DAYS,
  GTM_COOKIE_NAME,
  HOTJAR_COOKIE_NAME,
} from '../../../utils/cookie-consts';

const API_URL = process.env.API_URL;

type CookieBannerProps = {
  reloadAnalytics: () => void;
};

const CookieBanner = ({ reloadAnalytics }: CookieBannerProps) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept all"
      declineButtonText="Reject all"
      enableDeclineButton
      onAccept={() => {
        Cookies.set(GTM_COOKIE_NAME, COOKIE_ACCEPT, {
          expires: COOKIE_EXPIRATION_DAYS,
        });
        Cookies.set(HOTJAR_COOKIE_NAME, COOKIE_ACCEPT, {
          expires: COOKIE_EXPIRATION_DAYS,
        });
        reloadAnalytics();
      }}
      onDecline={() => {
        Cookies.set(GTM_COOKIE_NAME, COOKIE_DECLINE, {
          expires: COOKIE_EXPIRATION_DAYS,
        });
        Cookies.set(HOTJAR_COOKIE_NAME, COOKIE_DECLINE, {
          expires: COOKIE_EXPIRATION_DAYS,
        });
        reloadAnalytics();
      }}
      expires={COOKIE_EXPIRATION_DAYS}
      contentClasses="font-medium !m-0"
      containerClasses="!w-[94%] !bg-dark-slate/95 rounded-2xl text-soft-gray tracking-tight text-[17px] leading-5 p-[18px] !items-center flex gap-6 !left-[3%] !bottom-[2%]"
      buttonClasses="font-black !bg-soft-gray !text-dark-slate"
      buttonStyle={{
        margin: 0,
        borderRadius: '10px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '12px',
        paddingTop: '12px',
        borderWidth: '2px',
      }}
      declineButtonClasses="font-black !border-soft-gray !bg-dark-slate !border-2"
      declineButtonStyle={{
        marginRight: '8px',
        marginLeft: 0,
        marginBottom: 0,
        marginTop: 0,
        borderRadius: '10px',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '12px',
        paddingTop: '12px',
        border: '2px 2px',
      }}
    >
      We use cookies to improve user experience and analyze website traffic. By
      continuing to use this site, you agree to our{' '}
      <a
        href={`${API_URL}${pathToPrivacyPolicy}`}
        className="underline text-pure-cyan"
      >
        Privacy Policy
      </a>
      .
    </CookieConsent>
  );
};

export default CookieBanner;
