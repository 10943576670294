import serviceMenu from '../images/service-menu.webp';
import technologiesMenu from '../images/technologies-menu.webp';
import industriesMenu from '../images/industries-menu.webp';

export const listPathsOfIndustries = {
  health: '/industries/health-med-tech',
  visionPro: '/industries/apple-vision-pro-app-development-services',
  aws: '/industries/amazon-web-services-aws-cloud-dev',
  education: '/industries/education-software-development-services',
  retail: '/industries/retail-software-development-services',
  media: '/industries/media-and-entertainment-software-development',
  fintech: '/industries/fintech-software-development-services',
  hr: '/industries/hr-software-development-services',
  ecommerce: '/industries/ecommerce-software-development-services',
  telemedicine: '/industries/telemedicine-app-development-services',
  cloudCost: '/industries/cloud-cost-optimization-services',
  ehrAndEmr: '/industries/ehr-and-emr-software-development',
  serverless: '/industries/serverless-application-development-services',
  healthInfoExchange: '/industries/health-information-exchange-software',
  mentalHealth: '/industries/mental-health-app-development-services',
  fitnessApp: '/industries/fitness-app-development-services',
  wellnessApp: '/industries/wellness-app-development-services',
  oculusMetaQuest: '/industries/oculus-meta-quest-app-development-services',
  sportsAppDev: '/industries/sports-app-development-company',
};

const {
  health,
  visionPro,
  aws,
  education,
  retail,
  media,
  fintech,
  hr,
  ecommerce,
  telemedicine,
  cloudCost,
  ehrAndEmr,
  serverless,
  healthInfoExchange,
  mentalHealth,
  fitnessApp,
  wellnessApp,
  oculusMetaQuest,
  sportsAppDev,
} = listPathsOfIndustries;

export const paths = [
  {
    path: '/industries',
    name: 'Industries',
    image: industriesMenu,
    children: [
      {
        path: health,
        name: 'Healthcare',
      },
      {
        path: visionPro,
        name: 'Apple Vision Pro',
      },
      {
        path: aws,
        name: 'Amazon Web Services',
      },
      {
        path: education,
        name: 'Education',
      },
      {
        path: retail,
        name: 'Retail',
      },
      {
        path: media,
        name: 'Media & Entertainment',
      },
      {
        path: fintech,
        name: 'Fintech',
      },
      {
        path: hr,
        name: 'Human Resources',
      },
      {
        path: ecommerce,
        name: 'eCommerce',
      },
      {
        path: telemedicine,
        name: 'Telemedicine',
      },
      {
        path: cloudCost,
        name: 'Cloud Cost Optimization',
      },
      {
        path: ehrAndEmr,
        name: 'EHR & EMR',
      },
      {
        path: serverless,
        name: 'Serverless Application',
      },
      {
        path: healthInfoExchange,
        name: 'Health Information Exchange',
      },
      {
        path: mentalHealth,
        name: 'Mental Health',
      },
      {
        path: fitnessApp,
        name: 'Fitness',
      },
      { path: wellnessApp, name: 'Wellness' },
      { path: oculusMetaQuest, name: 'Oculus Meta Quest' },
      { path: sportsAppDev, name: 'Sports Application' },
    ],
  },
  {
    path: '/services',
    name: 'Services',
    image: serviceMenu,
    children: [
      {
        path: '/services/custom-software-development',
        name: 'Custom Software Development Services',
      },
      {
        path: '/services/mvp-development',
        name: 'MVP Development Services',
      },
      {
        path: '/services/web-development',
        name: 'Web Development Services',
      },
      {
        path: '/services/saas-development',
        name: 'SaaS Development Company',
      },
      {
        path: '/services/mobile-app-development',
        name: 'Mobile App Development Services',
      },
      {
        path: '/services/custom-lms-development',
        name: 'LMS Development Services',
      },
      {
        path: '/services/ui-ux-design',
        name: 'UI/UX Design Services',
      },
      {
        path: '/services/legacy-code-refactoring',
        name: 'Legacy Code Refactoring Services',
      },
      {
        path: '/services/devops',
        name: 'DevOps Services',
      },
      {
        path: '/services/software-code-audit',
        name: 'Software Code Audit Services',
      },
    ],
  },
  {
    path: '/technologies',
    name: 'Technologies',
    image: technologiesMenu,
    children: [
      {
        path: '/technologies/node-js-development-company',
        name: 'Node.js Development Company',
      },
      {
        path: '/technologies/react-js-development-services',
        name: 'React JS Development Services',
      },
      {
        path: '/technologies/vue-js-development-company',
        name: 'Vue.js Development Company',
      },
      {
        path: '/technologies/ruby-on-rails-development-company',
        name: 'Ruby On Rails Development Company',
      },
      {
        path: '/technologies/python-development-company',
        name: 'Python Development Company',
      },
      {
        path: '/technologies/shopify-development-services',
        name: 'Shopify Development Services',
      },
      {
        path: '/technologies/django-development-company',
        name: 'Django Development Company',
      },
    ],
  },
  {
    path: '/cases',
    name: 'Cases',
  },
  {
    path: '/careers',
    name: 'Careers',
  },
  {
    path: '/blog',
    name: 'Blog',
  },
  {
    path: '/about',
    name: 'About us',
  },
  {
    path: '/contact',
    name: 'Contact us',
  },
];

export type Path = {
  readonly path: string;
  readonly name: string;
  readonly children?: readonly Path[];
  readonly image?: string;
};

export const footerNavItems = [
  {
    title: 'Cases',
    link: '/cases',
  },
  {
    title: 'Careers',
    link: '/careers',
  },
  {
    title: 'Blog',
    link: '/blog',
  },
  {
    title: 'About',
    link: '/about',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
] as const;

export const pathToHome = '/';
export const pathToAuthors = '/authors';
export const pathToCases = '/cases';
export const pathToContact = '/contact';
export const pathToCareers = '/careers';
export const pathToAboutUs = '/about';
export const pathToPrivacyPolicy = '/privacy-policy';
export const pathToTermsAndConditions = '/terms-and-conditions';
export const pathToBlog = '/blog';
export const pathToServices = '/services';
export const pathToCVSent = `${pathToCareers}/cv-sent`;
export const pathToIndustries = '/industries';
export const pathToTags = '/blog/tags';
export const pathToErrorSent = '/error-sent';
export const pathToRequestSent = '/request-sent';
export const pathToTechnologies = '/technologies';

export const servicesPageName = 'Services';
export const industriesPageName = 'Industries';
export const technologiesPageName = 'Technologies';
